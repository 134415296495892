import { Casts, Model, Store } from 'store/Base';
import { observable, runInAction } from 'mobx';
import { ImageStore } from 'store/Image';
import { Article } from 'store/Article';
import { TransportBatch } from 'store/TransportBatch';
import { showSaveNotification } from 'helpers/notification';
import { User } from 'store/User';

export const STATIC_DEFECT_ORIGIN_TRANSPORT = 'transport';
export const STATIC_DEFECT_ORIGIN_DISTRIBUTOR = 'distributor';
export const STATIC_DEFECT_ORIGIN_SUPPLIER = 'supplier';
export const STATIC_DEFECT_ORIGIN_UNKNOWN = 'unknown';
export const STATIC_DEFECT_ORIGIN_OPTIONS = [
    STATIC_DEFECT_ORIGIN_SUPPLIER,
    STATIC_DEFECT_ORIGIN_TRANSPORT,
    STATIC_DEFECT_ORIGIN_DISTRIBUTOR,
    STATIC_DEFECT_ORIGIN_UNKNOWN,
];


export class Defect extends Model {
    static backendResourceName = 'defect';

    @observable id = null;
    @observable origin;
    @observable description;
    @observable missing= false;
    @observable quantity = 1;
    @observable createdAt = null;
    @observable syncToExact = true;

    relations() {
        return {
            images: ImageStore,
            article: Article,
        transportBatch: TransportBatch,
            createdBy: User,
        };
    }
    casts() {
        return {
            createdAt: Casts.datetime,
        };
    }

    async resolve() {

        if (!window.confirm(t('form.deleteConfirmation'))) {
            return Promise.reject();
        }

        await this.wrapPendingRequestCount(this.api.post(`${this.url}resolve/`));
        runInAction(() => {
            showSaveNotification();
            this.error = null;
        });
    }
}

export class DefectStore extends Store {
    Model = Defect;
    static backendResourceName = 'defect';
}