import { observable, computed } from 'mobx';
import { Model, Store } from 'store/Base';
import { SalesOrderLineStore } from './SalesOrderLine';
import { PurchaseOrderStore } from './PurchaseOrder';
import { Supplier } from './Supplier';
import { Stock, StockStore } from './Stock';
import { ArticleGroup } from './ArticleGroup';
import { showSaveNotification } from 'helpers/notification';

export class Article extends Model {
    static backendResourceName = 'article';
    static omitFields = [
        'diva_current_stock',
        'diva_planning_in',
        'diva_planning_out',
        'diva_projected_stock',
        'diva_defects',
    ];

    @observable id = null;
    @observable name = '';
    @observable description = '';
    @observable code = '';
    @observable supplierCode = '';
    @observable supplierName = '';
    @observable productionTime = 0;
    @observable transportTime = 0;
    @observable fromStock = false;
    @observable minStockLevel = 0;
    @observable maxStockLevel = 0;
    @observable cbm = 0;
    @observable kg = 0;

    @observable exactCurrentStock = 0;
    @observable exactPlanningIn = 0;
    @observable exactPlanningOut = 0;
    @observable exactProjectedStock = 0;
    @observable exactDefects = 0;

    @observable divaCurrentStock = 0;
    @observable divaPlanningIn = 0;
    @observable divaPlanningOut = 0;
    @observable divaProjectedStock = 0;
    @observable divaDefects = 0;

    @observable jkCurrentStock = 0;
    @observable jkReservedStock = 0;
    @observable jkOutgoingStock = 0;
    @observable jkIncomingStock = 0;


    @computed get labelUrl() {
        return ArticleStore.labelUrl(`1x${this.id}`);
    }

    relations() {
        return {
            supplier: Supplier,
            salesOrderLines: SalesOrderLineStore,
            purchaseOrderLines: PurchaseOrderStore,
            currentStock: Stock,
            projectedStock: Stock,
            stocks: StockStore,
            articleGroup: ArticleGroup,
        };
    }

    async addStock(quantity, reason) {
        await this.wrapPendingRequestCount(this.api.post(`${this.url}add_stock/`, { added_quantity:quantity, reason:reason }));
        showSaveNotification();
    }
}

// NOTE: this class right now exists only in the frontend in order to take advanteage of TargetSelects
// Saving will not work!
export class ArticleStockIncrease extends Model {

    static backendResourceName = 'articleStockIncrease';

    @observable quantity = 0;
    @observable reason = '';

    save() {
    }

    relations() {
        return {
            article: Article
        }
    }
}

export class ArticleStore extends Store {
    Model = Article;
    static backendResourceName = 'article';

    static labelUrl(articleCounts) {
        return `/api/article/download-barcode/?articles=${articleCounts.toString()}`;
    }

}
