import { observable } from 'mobx';
import { Model, Store } from 'store/Base';

export class ArticleGroup extends Model {
    static backendResourceName = 'article_group';

    @observable id = null;
    @observable code = '';
    @observable description = '';
}

export class ArticleGroupStore extends Store {
    Model = ArticleGroup;
    static backendResourceName = 'article_group';
}
